"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "chat", {
    enumerable: true,
    get: function() {
        return chat;
    }
});
var chat = function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        unreadCount: 0,
        logout: false,
        status: 'online'
    }, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'SET_UNREAD_COUNT':
            state.unreadCount = Number.isInteger(action.unreadCount) && action.unreadCount || 0;
            return state;
        case 'CHAT_LOGOUT':
            if (action.completed) {
                state.logout = true;
            }
            return state;
        case 'SET_STATUS':
            state.status = action.status;
            return state;
        default:
            return state;
    }
};
