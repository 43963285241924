"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    onShareAddPeer: function() {
        return onShareAddPeer;
    },
    onShareCancel: function() {
        return onShareCancel;
    },
    onShareClick: function() {
        return onShareClick;
    },
    onShareClose: function() {
        return onShareClose;
    },
    onShareFilterChange: function() {
        return onShareFilterChange;
    },
    onShareRemovePeer: function() {
        return onShareRemovePeer;
    },
    onShareSend: function() {
        return onShareSend;
    }
});
var onShareClick = function() {
    return {
        type: 'GRID_SHARE_ONCLICK'
    };
};
var onShareClose = function() {
    return {
        type: 'GRID_SHARE_ONCLOSE'
    };
};
var onShareSend = function() {
    return {
        type: 'GRID_SHARE_ONSEND'
    };
};
var onShareCancel = function() {
    return {
        type: 'GRID_SHARE_ONCANCEL'
    };
};
var onShareRemovePeer = function(id) {
    return {
        type: 'GRID_SHARE_REMOVE_PEER',
        id: id
    };
};
var onShareAddPeer = function(peer) {
    return {
        type: 'GRID_SHARE_ADD_PEER',
        peer: peer
    };
};
var onShareFilterChange = function(filter) {
    return {
        type: 'GRID_SHARE_FILTER_CHANGE',
        filter: filter
    };
};
