"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
var compareChatApp = function(chatApp1, chatApp2) {
    return(// summary
    //   return true if chatApp1 is equal to chatApp2, considering
    //   only the parameters that unique identify an app.
    chatApp1 && chatApp2 && chatApp1.chatPersonID === chatApp2.chatPersonID && chatApp1.appCode === chatApp2.appCode && chatApp1.appUserID === chatApp2.appUserID);
};
var updateSubscription = function(state, action) {
    if (!action.error && !action.fetching) {
        var newSubscription = action.subscription || action.response;
        var newList = state.appSubscriptions.list.filter(function(subscription) {
            return !compareChatApp(subscription, newSubscription);
        });
        newList.push(newSubscription);
        var newSelectedSubscription = null;
        if (compareChatApp(state.selectedSubscription, newSubscription)) {
            newSelectedSubscription = newSubscription;
        } else {
            newSelectedSubscription = state.selectedSubscription;
        }
        return _object_spread_props(_object_spread({}, state), {
            selectedSubscription: newSelectedSubscription,
            appSubscriptions: _object_spread_props(_object_spread({}, state.appSubscriptions), {
                list: newList
            })
        });
    }
    return state;
};
var createSubscription = function(state, action) {
    if (!action.error && !action.fetching) {
        var subscription = action.subscription || action.response;
        var existingSubscription = state.appSubscriptions.list.find(function(s) {
            return compareChatApp(s, subscription);
        });
        if (existingSubscription) {
            return updateSubscription(state, action);
        }
        var nextSubscriptionsList = state.appSubscriptions.list.concat(subscription);
        return _object_spread_props(_object_spread({}, state), {
            appSubscriptions: _object_spread_props(_object_spread({}, state.appSubscriptions), {
                list: nextSubscriptionsList
            })
        });
    }
    return state;
};
var deleteSubscription = function(state, action) {
    if (!action.error && !action.fetching) {
        var deletedSubscription = action.subscription || action.response;
        var wasSelected = compareChatApp(state.selectedSubscription, deletedSubscription);
        var selectedSubscription = wasSelected ? null : state.selectedSubscription;
        var list = state.appSubscriptions.list.filter(function(subscription) {
            return !compareChatApp(subscription, deletedSubscription);
        });
        return _object_spread_props(_object_spread({}, state), {
            selectedSubscription: selectedSubscription,
            appSubscriptions: _object_spread_props(_object_spread({}, state.appSubscriptions), {
                list: list
            })
        });
    }
    return state;
};
var _default = function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {
        navigationStack: [],
        selectedSubscription: null,
        logout: false,
        appSubscriptions: {
            load: true,
            loading: false,
            error: false,
            list: []
        }
    }, action = arguments.length > 1 ? arguments[1] : void 0;
    var newStack;
    switch(action.type){
        case 'INVALIDATE_SESSION':
            if (!action.fetching) {
                return _object_spread_props(_object_spread({}, state), {
                    logout: true,
                    sessionValidation: false
                });
            }
            return state;
        case 'LOGOUT':
            if (!action.error && !action.fetching) {
                return _object_spread_props(_object_spread({}, state), {
                    logout: true,
                    sessionValidation: false
                });
            }
            return state;
        case 'NAVIGATE_TO_SETTINGS':
            newStack = state.navigationStack.concat([
                {
                    settingsID: action.settingsID
                }
            ]);
            return _object_spread_props(_object_spread({}, state), {
                navigationStack: newStack
            });
        case 'NAVIGATE_BACK_TO_SETTINGS':
            newStack = state.navigationStack.concat([]);
            newStack.pop();
            return _object_spread_props(_object_spread({}, state), {
                navigationStack: newStack
            });
        case 'RESET_SETTINGS_NAVIGATION_HISTORY':
            return _object_spread_props(_object_spread({}, state), {
                navigationStack: []
            });
        case 'SETTINGS_SELECT_SUBSCRIPTION':
            return _object_spread_props(_object_spread({}, state), {
                selectedSubscription: action.subscription
            });
        case 'SETTINS_LOAD_APP_SUBSCRIPTIONS':
            if (!action.error && !action.fetching) {
                return _object_spread_props(_object_spread({}, state), {
                    appSubscriptions: {
                        load: false,
                        loading: action.fetching,
                        error: action.error,
                        list: action.response || state.appSubscriptions.list
                    }
                });
            }
            return state;
        case 'SETTINGS_CREATE_CHAT_APP_SUBSCRIPTION':
            return createSubscription(state, action);
        case 'SETTINGS_UPDATE_CHAT_APP_SUBSCRIPTION':
            return updateSubscription(state, action);
        case 'SETTINGS_DELETE_CHAT_APP_SUBSCRIPTION':
            return deleteSubscription(state, action);
        default:
            return state;
    }
};
