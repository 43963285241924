"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    getImpersonationMode: function() {
        return getImpersonationMode;
    },
    isImpersonating: function() {
        return isImpersonating;
    },
    isInFullImpersonationMode: function() {
        return isInFullImpersonationMode;
    }
});
var isImpersonating = function(user) {
    return user && user.actingAsMode && user.actingAsMode !== 'CPAPartner';
};
var isInFullImpersonationMode = function(user) {
    return user && user.actingAsMode === 'Impersonation';
};
var getImpersonationMode = function(user) {
    if (!isImpersonating(user)) {
        return null;
    }
    return user && user.actingAsMode;
};
