"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var createScriptTag = function(src, backupSrc) {
    var scriptElem = document.createElement('script');
    scriptElem.src = src;
    if (backupSrc) {
        scriptElem.onerror = function() {
            document.body.appendChild(createScriptTag(backupSrc));
        };
    }
    return scriptElem;
};
var _default = createScriptTag;
