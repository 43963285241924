"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    getRowsComparisonMode: function() {
        return getRowsComparisonMode;
    },
    getRowsDiffList: function() {
        return getRowsDiffList;
    },
    setRowsComparisonMode: function() {
        return setRowsComparisonMode;
    }
});
var _rowsDiffSortComparator = require("./rowsDiffSortComparator");
var compareRowIdSequence = 0;
var currentRowsComparisonMode = 'NONE';
var percentDiff = function(a, b) {
    return Math.round((a - b) / Math.abs(b) * 10000) / 100;
};
var numericDiff = function(currentValue, nextValue, rowsComparisonMode) {
    var fcurrent = parseFloat(currentValue, 10);
    var fnext = parseFloat(nextValue, 10);
    var result = null;
    if (isNaN(fcurrent)) {
        fcurrent = 0;
    }
    if (isNaN(fnext)) {
        fnext = 0;
    }
    switch(rowsComparisonMode){
        case 'PCT_AB':
            if (fcurrent === 0) {
                result = null;
            } else {
                result = percentDiff(fnext, fcurrent);
            }
            break;
        case 'PCT_BA':
            if (fnext === 0) {
                result = null;
            } else {
                result = percentDiff(fcurrent, fnext);
            }
            break;
        case 'DIFF_AB':
            result = fcurrent - fnext;
            break;
        case 'DIFF_BA':
            result = fnext - fcurrent;
            break;
        case 'AVG':
            result = Math.round((fcurrent + fnext) / 2 * 100) / 100;
            break;
        case 'TOT':
            result = fcurrent + fnext;
            break;
        default:
            throw new Error("Unknown comparison mode ".concat(rowsComparisonMode));
    }
    return result;
};
var getNextCompareRowId = function() {
    var rowId = "DIFF_ROW_".concat(compareRowIdSequence);
    compareRowIdSequence += 1;
    return rowId;
};
var isEqualCurrencyType = function(currentRow, nextRow, colDef) {
    var currentCode = currentRow.data[colDef.currencyCodeColumn];
    var nextCode = nextRow.data[colDef.currencyCodeColumn];
    return currentCode === nextCode;
};
var getRowsDiff = function(currentRow, nextRow, rowsComparisonMode, columns, rowsDiffSortIndex) {
    var currentRowId = currentRow.id;
    var compareRowId = getNextCompareRowId();
    var nextRowId = nextRow.id;
    var row = {
        isDiffRow: true,
        insertItemAtIndex: parseInt(nextRowId, 10),
        compareRowId: compareRowId,
        rowsComparisonMode: rowsComparisonMode,
        comparedValues: {}
    };
    columns.forEach(function(column) {
        var colDef = column.colDef;
        if (!colDef.field) {
            return;
        }
        if (!column.visible) {
            return;
        }
        var currentValue = currentRow.valueService.getValue(column, currentRow);
        var nextValue = nextRow.valueService.getValue(column, nextRow);
        var result = null;
        row.comparedValues[colDef.field] = {
            currentValue: (0, _rowsDiffSortComparator.getColumnValue)(currentValue),
            nextValue: (0, _rowsDiffSortComparator.getColumnValue)(nextValue)
        };
        if (colDef.dataType === 'currency' && colDef.currencyCodeColumn && isEqualCurrencyType(currentRow, nextRow, colDef)) {
            row[colDef.currencyCodeColumn] = currentRow.data[colDef.currencyCodeColumn] || 'USD';
        }
        switch(colDef.dataType){
            case 'currency':
            case 'double':
            case 'integer':
                result = numericDiff(currentValue, nextValue, rowsComparisonMode);
                break;
            default:
                result = currentValue;
                break;
        }
        row[colDef.field] = result;
    });
    rowsDiffSortIndex.pop();
    rowsDiffSortIndex.push(currentRowId, compareRowId, nextRowId);
    return row;
};
var setRowsComparisonMode = function(newRowscomparisonMode) {
    currentRowsComparisonMode = newRowscomparisonMode;
};
var getRowsComparisonMode = function() {
    return currentRowsComparisonMode;
};
var getRowsDiffList = function(pinRowsFilterActive, rowsComparisonMode, rowModel, columns) {
    compareRowIdSequence = 0;
    (0, _rowsDiffSortComparator.resetRowsDiffSortIndex)();
    var rowsComparisonData = [];
    var rowsDiffSortIndex = (0, _rowsDiffSortComparator.getRowsDiffSortIndex)();
    setRowsComparisonMode(rowsComparisonMode);
    if (pinRowsFilterActive && rowsComparisonMode !== 'NONE') {
        var rowCount = rowModel.getRowCount();
        var rowLimit = rowCount - 1;
        var rowPtr = 0;
        var currentRow = null;
        var nextRow = null;
        while(rowPtr < rowLimit){
            currentRow = rowModel.getRow(rowPtr);
            nextRow = rowModel.getRow(rowPtr + 1);
            rowsComparisonData.push(getRowsDiff(currentRow, nextRow, rowsComparisonMode, columns, rowsDiffSortIndex));
            rowPtr += 1;
        }
    }
    return rowsComparisonData;
};
