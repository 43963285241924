"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    startConversation: function() {
        return startConversation;
    },
    startConversationFromGrid: function() {
        return startConversationFromGrid;
    }
});
var _endpoints = require("../utils/endpoints");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
var startConversation = function(param) {
    var canonical = param.canonical, eventID = param.eventID, eventParams = param.eventParams, actionDetails = param.actionDetails;
    return {
        type: 'START_CONV',
        emitParams: {
            responseEventName: 'START_CONV_RESP',
            canonical: canonical,
            eventID: eventID,
            actionDetails: actionDetails,
            eventParams: eventParams
        }
    };
};
var startConversationFromGrid = function(param) {
    var actionDetails = param.actionDetails, canonical = param.canonical, intent = param.intent, eventID = param.eventID, viewpointUserContext = param.viewpointUserContext, viewpointID = param.viewpointID;
    return {
        type: 'START_CONVERSATION_FROM_GRID',
        api: {
            method: 'POST',
            endpoint: "".concat(_endpoints.practitionerServicesURL, "/v1/viewpoint/startConversation"),
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(_object_spread_props(_object_spread({}, viewpointUserContext), {
                action: actionDetails,
                canonical: canonical,
                intent: intent,
                eventID: eventID,
                viewpointID: viewpointID
            }))
        }
    };
};
