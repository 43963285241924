"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    navigateToSharedViewpoint: function() {
        return navigateToSharedViewpoint;
    },
    navigateToViewpoint: function() {
        return navigateToViewpoint;
    }
});
var navigateToViewpoint = function(perspectiveID, viewpointID) {
    return {
        type: 'NAVIGATE_TO_VIEWPOINT',
        perspectiveID: perspectiveID,
        viewpointID: viewpointID
    };
};
var navigateToSharedViewpoint = function(viewpointID, ownerID) {
    return {
        type: 'NAVIGATE_TO_SHARED_VIEWPOINT',
        viewpointID: viewpointID,
        ownerID: ownerID
    };
};
