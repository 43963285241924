"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    getCurrentRowDataFromAgGrid: function() {
        return getCurrentRowDataFromAgGrid;
    },
    getParentRowIfAvailable: function() {
        return getParentRowIfAvailable;
    }
});
function getParentRowIfAvailable(selectedRows, routerParams) {
    var childViewpointID = routerParams.childViewpointID, parentViewpointID = routerParams.parentViewpointID;
    if (childViewpointID) {
        return selectedRows[parentViewpointID] && selectedRows[parentViewpointID].data;
    }
    return {};
}
function getCurrentRowDataFromAgGrid(api, focusedRowIndex) {
    if (api && focusedRowIndex > -1) {
        var row = api.getModel().getRow(focusedRowIndex);
        if (row) {
            return row.data;
        }
    }
    return {};
}
