"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return mapDataToRows;
    }
});
function _instanceof(left, right) {
    if (right != null && typeof Symbol !== "undefined" && right[Symbol.hasInstance]) {
        return !!right[Symbol.hasInstance](left);
    } else {
        return left instanceof right;
    }
}
var mapDataToRows = function() {
    var data = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
    return data ? data.map(function(item) {
        var dict = {};
        var dataKeys = item.map(function(field, index) {
            if (_instanceof(field, Object)) {
                field.toString = function() {
                    return field.stringified ? field.stringified : '';
                };
            }
            dict["column_".concat(index)] = field;
            return dict;
        }).shift();
        return dataKeys;
    }) : [];
};
