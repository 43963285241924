"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "setPeopleTimelineOpenedStatus", {
    enumerable: true,
    get: function() {
        return setPeopleTimelineOpenedStatus;
    }
});
var setPeopleTimelineOpenedStatus = function(status) {
    return {
        type: 'SET_PEOPLE_TIMELINE_OPENED_STATUS',
        status: status
    };
};
