"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    clearViewpointFilterState: function() {
        return clearViewpointFilterState;
    },
    getViewpointFilterData: function() {
        return getViewpointFilterData;
    },
    setViewpointFilterState: function() {
        return setViewpointFilterState;
    }
});
var _endpoints = require("../utils/endpoints");
var viewpointService = "".concat(_endpoints.practitionerServicesURL, "/v1/viewpoint");
var setViewpointFilterState = function(viewpointFilterState) {
    return {
        type: 'SET_VIEWPOINT_FILTER_STATE',
        viewpointFilterState: viewpointFilterState
    };
};
var clearViewpointFilterState = function(viewpointID) {
    return {
        type: 'CLEAR_VIEWPOINT_FILTER_STATE',
        viewpointID: viewpointID
    };
};
var getViewpointFilterData = function(param) {
    var viewpointID = param.viewpointID, dataProvider = param.dataProvider, params = param.params;
    return {
        type: 'GET_VIEWPOINT_FILTER_DATA',
        dataProvider: dataProvider,
        params: params,
        api: {
            method: 'POST',
            endpoint: "".concat(viewpointService, "/viewpointFilter?viewpointID=").concat(viewpointID),
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                dataProvider: dataProvider,
                params: params
            })
        }
    };
};
