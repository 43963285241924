"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    getSocketConnection: function() {
        return getSocketConnection;
    },
    removeSocketToken: function() {
        return removeSocketToken;
    },
    setSocketAuthenticated: function() {
        return setSocketAuthenticated;
    },
    setSocketConnection: function() {
        return setSocketConnection;
    },
    socketDisconnected: function() {
        return socketDisconnected;
    },
    socketReConnected: function() {
        return socketReConnected;
    },
    socketReconnectionAttempt: function() {
        return socketReconnectionAttempt;
    }
});
var setSocketAuthenticated = function(authenticated) {
    return {
        type: 'SET_SOCKET_AUTHENTICATED',
        authenticated: authenticated
    };
};
var getSocketConnection = function() {
    return {
        type: 'GET_SOCKET_CONNECTION'
    };
};
var setSocketConnection = function(socket) {
    return {
        type: 'SET_SOCKET_CONNECTION',
        connected: socket.connected
    };
};
var removeSocketToken = function() {
    return {
        type: 'REMOVE_SOCKET_TOKEN'
    };
};
var socketReConnected = function() {
    return {
        type: 'SOCKET_RECONNECTED'
    };
};
var socketDisconnected = function(param) {
    var reason = param.reason, willRetry = param.willRetry;
    return {
        type: 'SOCKET_DISCONNECTED',
        reason: reason,
        willRetry: willRetry
    };
};
var socketReconnectionAttempt = function(param) {
    var attemptNumber = param.attemptNumber, backoffTimeoutMS = param.backoffTimeoutMS;
    return {
        type: 'SOCKET_RECONNECTION_ATTEMPT',
        attemptNumber: attemptNumber,
        backoffTimeoutMS: backoffTimeoutMS
    };
};
