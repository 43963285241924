"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    display: function() {
        return display;
    },
    getPopupData: function() {
        return getPopupData;
    },
    hide: function() {
        return hide;
    }
});
var _endpoints = require("../utils/endpoints");
var display = function(viewpointAction) {
    return {
        type: 'DISPLAY_POPUP',
        viewpointAction: viewpointAction
    };
};
var hide = function() {
    return {
        type: 'HIDE_POPUP'
    };
};
var getPopupData = function(viewpointID, row, viewpointAction) {
    return {
        type: 'GET_POPUP_DATA',
        viewpointAction: viewpointAction,
        api: {
            method: 'POST',
            endpoint: "".concat(_endpoints.practitionerServicesURL, "/v1/viewpoint/popup?viewpointID=").concat(viewpointID),
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                row: row,
                viewpointAction: viewpointAction
            })
        }
    };
};
