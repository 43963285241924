"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    mapFilterAttributeIDToColId: function() {
        return mapFilterAttributeIDToColId;
    },
    mapFilterColIdToAttributeID: function() {
        return mapFilterColIdToAttributeID;
    },
    mapSortAttributeIDToColId: function() {
        return mapSortAttributeIDToColId;
    },
    mapSortColIdToAttributeID: function() {
        return mapSortColIdToAttributeID;
    }
});
var _ColumnProperties = require("./ColumnProperties");
var mapSortAttributeIDToColId = function(sort, columnApi) {
    var mapAttributeIDToColId = function(sortColumn) {
        var gridColumn = columnApi.getAllGridColumns().find(function(c) {
            return c && c.colDef && c.colDef.attributeID === sortColumn.attributeID;
        });
        return {
            colId: gridColumn && gridColumn.colId,
            sort: sortColumn.sort
        };
    };
    return sort.map(mapAttributeIDToColId).filter(function(entry) {
        return !!entry.colId;
    });
};
var mapSortColIdToAttributeID = function(sort, columnApi) {
    var mapColIdToAttributeID = function(sortColumn) {
        var gridColumn = columnApi.getAllGridColumns().find(function(c) {
            return c && sortColumn && c.colId === sortColumn.colId;
        });
        return {
            attributeID: gridColumn && gridColumn.colDef && gridColumn.colDef.attributeID,
            sort: sortColumn.sort
        };
    };
    return sort.map(mapColIdToAttributeID).filter(function(entry) {
        return !!entry.attributeID;
    });
};
var mapFilterAttributeIDToColId = function(filter, columnApi) {
    var filterColumns = Object.keys(filter);
    return columnApi.getAllGridColumns().filter(function(column) {
        return !(0, _ColumnProperties.isIndexColumn)(column) && filterColumns.includes(column && column.colDef && column.colDef.attributeID);
    }).reduce(function(accumulator, column) {
        if (column) {
            accumulator[column.colId] = filter[column.colDef.attributeID];
        }
        return accumulator;
    }, {});
};
var mapFilterColIdToAttributeID = function(filter, columnApi) {
    var filterColumns = Object.keys(filter);
    return columnApi.getAllGridColumns().filter(function(column) {
        return !(0, _ColumnProperties.isIndexColumn)(column) && filterColumns.includes(column && column.colId);
    }).reduce(function(accumulator, column) {
        accumulator[column.colDef.attributeID] = filter[column.colId];
        return accumulator;
    }, {});
};
