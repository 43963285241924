"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var initialState = {
    title: ''
};
var _default = function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : initialState, action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'SET_HEADER_TITLE':
            return Object.assign({}, state, {
                title: action.title
            });
        case 'SET_REGION':
            return Object.assign({}, state, {
                region: action.region
            });
        case 'SET_REGION_FROM_URL':
            return Object.assign({}, state, {
                region: action.url.includes('.eu.') ? 'EU' : 'US'
            });
        case 'SET_POD':
            return Object.assign({}, state, {
                pod: action.pod
            });
        default:
            return state;
    }
};
