"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "priorityRowSortProcessor", {
    enumerable: true,
    get: function() {
        return priorityRowSortProcessor;
    }
});
var priorityColumnNumber;
function priorityRowSortProcessor(params) {
    var props = params.props, rowNodes = params.rowNodes;
    if (!props || !props.viewpoint || !props.viewpoint.meta || !props.viewpoint.meta.fields) {
        return params;
    }
    if (checkForEnablePrioritySort(props) !== -1) {
        if (rowNodes && rowNodes.length > 0) {
            rowNodes.sort(sortPartnerPortalRowNodes);
        }
    }
    return params;
}
function sortPartnerPortalRowNodes(a, b) {
    var _b_data, _a_data;
    return parseInt(b === null || b === void 0 ? void 0 : (_b_data = b.data) === null || _b_data === void 0 ? void 0 : _b_data["column_".concat(priorityColumnNumber)].value, 10) - parseInt(a === null || a === void 0 ? void 0 : (_a_data = a.data) === null || _a_data === void 0 ? void 0 : _a_data["column_".concat(priorityColumnNumber)].value, 10);
}
function checkForEnablePrioritySort(props) {
    priorityColumnNumber = props.viewpoint.meta.fields.findIndex(function(field) {
        return field.hasOwnProperty("enablePrioritySort") && field.enablePrioritySort && (field === null || field === void 0 ? void 0 : field.enablePrioritySort) === true;
    });
    return priorityColumnNumber;
}
