"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var _default = function(params) {
    var props = params.props, prevProps = params.prevProps, columnApi = params.columnApi;
    var _props_sharedViewpointDetails = props.sharedViewpointDetails, sharedViewpointDetails = _props_sharedViewpointDetails === void 0 ? {} : _props_sharedViewpointDetails;
    var pivotMode = sharedViewpointDetails.pivotMode;
    var prevPivotMode = prevProps.sharedViewpointDetails && prevProps.sharedViewpointDetails.pivotMode;
    var hasPivotModeChanged = Boolean(pivotMode) !== Boolean(prevPivotMode);
    if (hasPivotModeChanged) {
        columnApi.setPivotMode(pivotMode);
    }
    return params;
};
