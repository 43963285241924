"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    setHeaderTitle: function() {
        return setHeaderTitle;
    },
    setPod: function() {
        return setPod;
    },
    setRegion: function() {
        return setRegion;
    },
    setRegionFromUrl: function() {
        return setRegionFromUrl;
    },
    updateSession: function() {
        return updateSession;
    }
});
var _endpoints = require("../utils/endpoints");
var setHeaderTitle = function(title) {
    return {
        type: 'SET_HEADER_TITLE',
        title: title
    };
};
var setRegion = function(region) {
    return {
        type: 'SET_REGION',
        region: region
    };
};
var setRegionFromUrl = function(url) {
    return {
        type: 'SET_REGION_FROM_URL',
        url: url
    };
};
var setPod = function(pod) {
    return {
        type: 'SET_POD',
        pod: pod,
        api: {
            method: 'GET',
            endpoint: _endpoints.perspectiveService
        }
    };
};
var updateSession = function(clientID) {
    return {
        type: 'UPDATE_SESSION',
        api: {
            method: 'POST',
            endpoint: "".concat(_endpoints.userServicesURL, "/v1/session"),
            body: JSON.stringify({
                clientID: clientID
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        }
    };
};
