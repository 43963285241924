"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "mediaServerURLBuilder", {
    enumerable: true,
    get: function() {
        return mediaServerURLBuilder;
    }
});
var mediaServerURLBuilder = function(param) {
    var documentID = param.documentID, width = param.width, height = param.height;
    if (!documentID) {
        return '';
    }
    var params = width && height ? "?width=".concat(width, "&height=").concat(height) : '';
    return "/content/".concat(documentID).concat(params);
};
