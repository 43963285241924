"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    mapSharedViewpointDetailsToUnviewedList: function() {
        return mapSharedViewpointDetailsToUnviewedList;
    },
    mapSharedViewpointsToPerspectives: function() {
        return mapSharedViewpointsToPerspectives;
    }
});
var mapSharedViewpointDetailsToUnviewedList = function(unviewedViewpointsList, sharedViewpointDetails) {
    var owner = sharedViewpointDetails.ownerID;
    var viewpoint = sharedViewpointDetails.viewpointID;
    var index = "".concat(owner, "||").concat(viewpoint);
    var nextList = unviewedViewpointsList.concat([]);
    if (nextList.indexOf(index) === -1) {
        nextList.push(index);
    }
    return nextList;
};
var mapSharedViewpointsToPerspectives = function(sharedViewpointDetails) {
    var perspectives = [];
    var indexById = {};
    sharedViewpointDetails.forEach(function(item) {
        var key = "".concat(item.perspectiveID, "||").concat(item.ownerID);
        var perspective = null;
        if (typeof indexById[key] === 'number') {
            perspective = perspectives[indexById[key]];
        } else {
            perspective = {
                isSharedViewpoint: true,
                description: '',
                index: perspectives.length,
                perspectiveID: item.perspectiveID,
                lastViewed: '',
                pin: false,
                visible: true,
                title: item.perspectiveTitle,
                viewpointID: item.viewpointID,
                ownerID: item.ownerID,
                viewpoints: []
            };
            indexById[key] = perspectives.length;
            perspectives.push(perspective);
        }
        var viewpoint = {
            canonicalTitle: '',
            perspectiveID: perspective.perspectiveID,
            title: item.title,
            viewpointID: item.viewpointID,
            ownerID: item.ownerID
        };
        perspective.viewpoints.push(viewpoint);
    });
    return perspectives;
};
