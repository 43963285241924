"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "isCellEditable", {
    enumerable: true,
    get: function() {
        return isCellEditable;
    }
});
var _conditionEvaluator = require("../grid/utils/conditionEvaluator");
var _eventBuilder = require("./eventBuilder");
function evaluateConditions(param) {
    var editableConditions = param.editableConditions, values = param.values, fields = param.fields;
    if (editableConditions && Object.keys(editableConditions).length) {
        try {
            var data = (0, _eventBuilder.buildObjectFromEntireCellContents)({
                values: values,
                fields: fields
            });
            return (0, _conditionEvaluator.runConditions)(editableConditions, {
                data: data
            });
        } catch (e) {
            return false;
        }
    }
    return true;
}
function isCellEditable(param) {
    var editable = param.editable, editScenario = param.editScenario, colDef = param.colDef, fields = param.fields, values = param.values;
    var conditionsEvaluatedSuccessfully = evaluateConditions({
        editableConditions: colDef && colDef.editableConditions,
        values: values,
        fields: fields
    });
    if (!conditionsEvaluatedSuccessfully) {
        return false;
    }
    var alwaysEditableColumn = editable === 'Always';
    if (editScenario === 'insert') {
        return alwaysEditableColumn || editable === 'OnInsert';
    }
    return alwaysEditableColumn || editable === 'OnUpdate';
}
