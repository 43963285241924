"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    cellFocus: function() {
        return cellFocus;
    },
    closeCell: function() {
        return closeCell;
    },
    gridModelUpdate: function() {
        return gridModelUpdate;
    },
    loadingCellValueHistory: function() {
        return loadingCellValueHistory;
    },
    onChangePinRows: function() {
        return onChangePinRows;
    },
    onChangeRowsComparisonMode: function() {
        return onChangeRowsComparisonMode;
    },
    openCell: function() {
        return openCell;
    },
    removeComparisonData: function() {
        return removeComparisonData;
    },
    setAPIs: function() {
        return setAPIs;
    },
    setCellValueHistory: function() {
        return setCellValueHistory;
    },
    setColumns: function() {
        return setColumns;
    },
    setCommentsData: function() {
        return setCommentsData;
    },
    setData: function() {
        return setData;
    },
    setEditable: function() {
        return setEditable;
    },
    setFavorite: function() {
        return setFavorite;
    },
    setFontSize: function() {
        return setFontSize;
    },
    setGridTitleHeight: function() {
        return setGridTitleHeight;
    },
    setQuickFilterText: function() {
        return setQuickFilterText;
    },
    setReadyToSave: function() {
        return setReadyToSave;
    },
    setSelectionRange: function() {
        return setSelectionRange;
    },
    sync: function() {
        return sync;
    },
    updateRowsComparisonData: function() {
        return updateRowsComparisonData;
    }
});
var setColumns = function(props) {
    return {
        type: 'GRID_SET_COLUMNS',
        props: props
    };
};
var setData = function(props) {
    return {
        type: 'GRID_SET_DATA',
        props: props
    };
};
var sync = function(props) {
    return {
        type: 'GRID_SYNC',
        props: props
    };
};
var setEditable = function(editable) {
    return {
        type: 'GRID_SET_EDITABLE',
        editable: editable
    };
};
var setFavorite = function(favorite) {
    return {
        type: 'GRID_SET_FAVORITE',
        favorite: favorite
    };
};
var setQuickFilterText = function(text) {
    return {
        type: 'GRID_SET_QUICK_FILTER_TEXT',
        text: text
    };
};
var setSelectionRange = function() {
    var range = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [];
    return {
        type: 'GRID_SET_RANGE_SELECTION',
        range: range
    };
};
var setFontSize = function() {
    var size = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 'normal';
    return {
        type: 'GRID_SET_FONT_SIZE',
        size: size
    };
};
var cellFocus = function(column) {
    var rowIndex = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : -1, rowIDs = arguments.length > 2 ? arguments[2] : void 0, row = arguments.length > 3 ? arguments[3] : void 0, focusedCell = arguments.length > 4 ? arguments[4] : void 0;
    return {
        type: 'GRID_CELL_FOCUS',
        column: column,
        rowIndex: rowIndex,
        rowIDs: rowIDs,
        row: row,
        focusedCell: focusedCell
    };
};
var gridModelUpdate = function(data, columns) {
    return {
        type: 'GRID_MODEL_UPDATE',
        data: data,
        columns: columns
    };
};
var openCell = function(cellKey) {
    return {
        type: 'GRID_OPEN_CELL',
        cellKey: cellKey
    };
};
var closeCell = function(cellKey) {
    return {
        type: 'GRID_CLOSE_CELL',
        cellKey: cellKey
    };
};
var onChangePinRows = function(pinnedRows) {
    return {
        type: 'GRID_ON_CHANGE_PIN_ROWS',
        pinnedRows: pinnedRows
    };
};
var onChangeRowsComparisonMode = function(rowsComparisonMode) {
    return {
        type: 'GRID_ON_CHANGE_COMPARISON_ROWS_MODE',
        rowsComparisonMode: rowsComparisonMode
    };
};
var updateRowsComparisonData = function(rowModel, columns) {
    return {
        type: 'GRID_UPDATE_ROWS_COMPARISON_DATA',
        rowModel: rowModel,
        columns: columns
    };
};
var removeComparisonData = function() {
    return {
        type: 'GRID_REMOVE_ROWS_COMPARISON_DATA'
    };
};
var setAPIs = function(api, columnApi) {
    return {
        type: 'GRID_SET_API',
        api: api,
        columnApi: columnApi
    };
};
var setReadyToSave = function(readyToSave) {
    return {
        type: 'GRID_SET_READY_TO_SAVE',
        readyToSave: readyToSave
    };
};
var loadingCellValueHistory = function(loading) {
    return {
        type: 'GRID_LOADING_CELL_VALUE_HISTORY',
        loading: loading
    };
};
var setCellValueHistory = function(param) {
    var events = param.events, errorMessage = param.errorMessage;
    return {
        type: 'GRID_SET_CELL_VALUE_HISTORY',
        events: events,
        errorMessage: errorMessage
    };
};
var setCommentsData = function(commentsData) {
    return {
        type: 'GRID_SET_COMMENTS_DATA',
        commentsData: commentsData
    };
};
var setGridTitleHeight = function(height) {
    return {
        type: 'GRID_SET_TITLE_HEIGHT',
        height: height
    };
};
