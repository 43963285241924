"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    getUser: function() {
        return getUser;
    },
    setUser: function() {
        return setUser;
    }
});
var getUser = function() {
    var payload = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    return {
        type: 'GET_PERSON_INFO',
        emitParams: {
            responseEventName: 'GET_PERSON_INFO_RESP',
            payload: payload
        }
    };
};
var setUser = function(user) {
    return {
        type: 'SET_PERSON_INFO',
        user: user
    };
};
