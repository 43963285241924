"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "calculateGridContainerHeight", {
    enumerable: true,
    get: function() {
        return calculateGridContainerHeight;
    }
});
var calculateGridContainerHeight = function() {
    var pageLayout = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    // default grid height is defined on _grid.scss
    // at the moment the value is calc(100vh - 210px)
    // this is if perfect, except by 2 components that have variable height:
    // pre-filter ui and viewpoint title
    // So, these components will store their respective heights on state
    // after being rendered, then components like grid and charts and any other
    // that render as main page content should make use of this method
    // to dynamically adjust their heights
    var windowHeight = pageLayout.windowHeight, _pageLayout_filterPanelHeight = pageLayout.filterPanelHeight, filterPanelHeight = _pageLayout_filterPanelHeight === void 0 ? 0 : _pageLayout_filterPanelHeight, _pageLayout_gridTitleHeight = pageLayout.gridTitleHeight, gridTitleHeight = _pageLayout_gridTitleHeight === void 0 ? 0 : _pageLayout_gridTitleHeight, _pageLayout_impersonationHeaderHeight = pageLayout.impersonationHeaderHeight, impersonationHeaderHeight = _pageLayout_impersonationHeaderHeight === void 0 ? 0 : _pageLayout_impersonationHeaderHeight;
    if (filterPanelHeight || gridTitleHeight || impersonationHeaderHeight) {
        var elementsHeight = 138; // sum of height of all other static elements
        var totalHeight = windowHeight || document.documentElement.clientHeight;
        return totalHeight - (elementsHeight + (impersonationHeaderHeight || 0) + (filterPanelHeight || 0) + (gridTitleHeight || 0));
    }
    return 0; // use the default css rule
};
