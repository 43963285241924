"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    setInsightsPopupOpen: function() {
        return setInsightsPopupOpen;
    },
    setInsightsPopupPosition: function() {
        return setInsightsPopupPosition;
    },
    toggleInsightsPopupOpen: function() {
        return toggleInsightsPopupOpen;
    }
});
var toggleInsightsPopupOpen = function() {
    return {
        type: 'GRID_TOGGLE_INSIGHTS_POPUP_OPEN'
    };
};
var setInsightsPopupOpen = function(open, anchorNode, ignoreClickOutside) {
    return {
        type: 'GRID_SET_INSIGHTS_POPUP_OPEN',
        open: open,
        anchorNode: anchorNode,
        ignoreClickOutside: ignoreClickOutside
    };
};
var setInsightsPopupPosition = function(position) {
    return {
        type: 'GRID_SET_INSIGHTS_POPUP_POSITION',
        position: position
    };
};
