"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var highlightedHeaderClass = 'ag-col-selected';
function highlightColumnHeader(focusedColumn, headerGroup) {
    if (headerGroup && headerGroup.querySelectorAll) {
        var headers = headerGroup.querySelectorAll(".".concat(highlightedHeaderClass));
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            for(var _iterator = headers[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                var header = _step.value;
                header.classList.remove(highlightedHeaderClass);
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
        var header1 = headerGroup.querySelector('div[col-id="'.concat(focusedColumn.field, '"]'));
        if (header1) {
            header1.classList.add(highlightedHeaderClass);
        }
    }
}
var _default = function(params) {
    var _params_props = params.props, focusedColumn = _params_props.focusedColumn, api = _params_props.api;
    if (focusedColumn) {
        var headerGroups = api.rowModel ? [
            api.gridPanel.headerRootComp.eHeaderContainer,
            api.gridPanel.headerRootComp.ePinnedLeftHeader,
            api.gridPanel.headerRootComp.ePinnedRightHeader
        ] : [];
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            for(var _iterator = headerGroups[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                var headerGroup = _step.value;
                highlightColumnHeader(focusedColumn, headerGroup);
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
    }
    return params;
};
