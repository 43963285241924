"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "default", {
    enumerable: true,
    get: function() {
        return _default;
    }
});
var _default = function() {
    var state = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [], action = arguments.length > 1 ? arguments[1] : void 0;
    switch(action.type){
        case 'SET_OPPORTUNITIES':
            return action.opportunities || state;
        default:
            return state;
    }
};
