"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    clearSubMenuTimer: function() {
        return clearSubMenuTimer;
    },
    closeContextMenu: function() {
        return closeContextMenu;
    },
    openContextMenu: function() {
        return openContextMenu;
    },
    setContextMenuPosition: function() {
        return setContextMenuPosition;
    },
    setupSubMenuTimer: function() {
        return setupSubMenuTimer;
    },
    toggleContextMenu: function() {
        return toggleContextMenu;
    }
});
var openContextMenu = function(param) {
    var menuID = param.menuID, _param_parentMenuID = param.parentMenuID, parentMenuID = _param_parentMenuID === void 0 ? null : _param_parentMenuID, anchorNode = param.anchorNode, component = param.component;
    return {
        type: 'GRID_CONTEXT_MENU_OPEN',
        menuID: menuID,
        parentMenuID: parentMenuID,
        anchorNode: anchorNode,
        component: component
    };
};
var toggleContextMenu = function(param) {
    var menuID = param.menuID, _param_parentMenuID = param.parentMenuID, parentMenuID = _param_parentMenuID === void 0 ? null : _param_parentMenuID, anchorNode = param.anchorNode, component = param.component;
    return {
        type: 'GRID_CONTEXT_MENU_TOGGLE',
        menuID: menuID,
        parentMenuID: parentMenuID,
        anchorNode: anchorNode,
        component: component
    };
};
var closeContextMenu = function(menuID) {
    return {
        type: 'GRID_CONTEXT_MENU_CLOSE',
        menuID: menuID
    };
};
var setContextMenuPosition = function(menuID, position) {
    return {
        type: 'GRID_CONTEXT_MENU_SET_POSITION',
        menuID: menuID,
        position: position
    };
};
var setupSubMenuTimer = function(param) {
    var subMenuActionID = param.subMenuActionID, menuID = param.menuID, _param_open = param.open, open = _param_open === void 0 ? true : _param_open, _param_clear = param.clear, clear = _param_clear === void 0 ? false : _param_clear, timeoutMS = param.timeoutMS;
    return {
        type: 'GRID_CONTEXT_MENU_SETUP_SUBMENU_TIMER',
        subMenuActionID: subMenuActionID,
        menuID: menuID,
        open: open,
        clear: clear,
        timeoutMS: timeoutMS
    };
};
var clearSubMenuTimer = function(param) {
    var _param_subMenuActionID = param.subMenuActionID, subMenuActionID = _param_subMenuActionID === void 0 ? null : _param_subMenuActionID, menuID = param.menuID;
    return {
        type: 'GRID_CONTEXT_MENU_CLEAR_SUBMENU_TIMER',
        subMenuActionID: subMenuActionID,
        menuID: menuID
    };
};
