"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    beforeLoadChildViewpoint: function() {
        return beforeLoadChildViewpoint;
    },
    removeChildViewpointNavigation: function() {
        return removeChildViewpointNavigation;
    },
    setCurrentChildViewpoint: function() {
        return setCurrentChildViewpoint;
    },
    setLoading: function() {
        return setLoading;
    }
});
var beforeLoadChildViewpoint = function(perspectiveID, parentViewpointID, childViewpointID) {
    return {
        type: 'BEFORE_LOAD_CHILD_VIEWPOINT',
        perspectiveID: perspectiveID,
        parentViewpointID: parentViewpointID,
        childViewpointID: childViewpointID
    };
};
var removeChildViewpointNavigation = function(viewpointNavigationRecord) {
    return {
        type: 'NAVIGATION_REMOVE_CHILD_NAVIGATION',
        viewpointNavigationRecord: viewpointNavigationRecord
    };
};
var setCurrentChildViewpoint = function(navigationRecord, index) {
    return {
        type: 'NAVIGATION_SET_CURRENT_CHILD_VIEWPOINT',
        navigationRecord: navigationRecord,
        index: index
    };
};
var setLoading = function(loading) {
    return {
        type: 'NAVIGATION_LOADING_DATA',
        loading: loading
    };
};
