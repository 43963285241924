"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    setChildViewpointContext: function() {
        return setChildViewpointContext;
    },
    setSelectedRow: function() {
        return setSelectedRow;
    }
});
var setSelectedRow = function(viewpointID, data, popupData, rowIndex, rowIDs) {
    return {
        type: 'SET_SELECTED_ROW',
        viewpointID: viewpointID,
        data: data,
        popupData: popupData,
        rowIndex: rowIndex,
        rowIDs: rowIDs
    };
};
var setChildViewpointContext = function(viewpointID, perspectiveID, childViewpointID, data, action) {
    return {
        type: 'SET_CHILD_VIEWPOINT_CONTEXT',
        viewpointID: viewpointID,
        perspectiveID: perspectiveID,
        childViewpointID: childViewpointID,
        data: data,
        viewpointAction: action
    };
};
