"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "setFilterPanelHeight", {
    enumerable: true,
    get: function() {
        return setFilterPanelHeight;
    }
});
var setFilterPanelHeight = function(height) {
    return {
        type: 'SET_VIEWPOINT_FILTER_HEIGHT',
        height: height
    };
};
