/* row height in accordance with font-sizes (compact, normal, large) */ "use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    GRID_ROW_HEIGHT_LARGE: function() {
        return GRID_ROW_HEIGHT_LARGE;
    },
    GRID_ROW_HEIGHT_NORMAL: function() {
        return GRID_ROW_HEIGHT_NORMAL;
    },
    GRID_ROW_HEIGHT_SMALL: function() {
        return GRID_ROW_HEIGHT_SMALL;
    }
});
var GRID_ROW_HEIGHT_SMALL = 25;
var GRID_ROW_HEIGHT_NORMAL = 28;
var GRID_ROW_HEIGHT_LARGE = 50;
