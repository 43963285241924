"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
function _export(target, all) {
    for(var name in all)Object.defineProperty(target, name, {
        enumerable: true,
        get: all[name]
    });
}
_export(exports, {
    codePolicyValidation: function() {
        return codePolicyValidation;
    },
    exchangeAuthCodeToAccessToken: function() {
        return exchangeAuthCodeToAccessToken;
    },
    forgotPassword: function() {
        return forgotPassword;
    },
    getAuthCode: function() {
        return getAuthCode;
    },
    getUserStatus: function() {
        return getUserStatus;
    },
    parseResponse: function() {
        return parseResponse;
    },
    passwordPolicyValidation: function() {
        return passwordPolicyValidation;
    },
    resetPassword: function() {
        return resetPassword;
    }
});
var _Prefix = require("../utils/Prefix");
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
var parseResponse = function(res) {
    if (res && res.ok) {
        return res.json();
    } else {
        throw new Error("".concat(res.status, " - ").concat(res.statusText, " from : ").concat(res.url));
    }
};
var apiParams = {
    method: "GET",
    credentials: "same-origin",
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
};
var getAuthCode = function(username, password) {
    return new Promise(function(resolve, reject) {
        fetch((0, _Prefix.getURLWithPrefix)("/workspace/login"), _object_spread_props(_object_spread({}, apiParams), {
            method: "POST",
            body: JSON.stringify({
                username: username.toLowerCase(),
                password: password
            })
        })).then(function(res) {
            return parseResponse(res);
        }).then(function(data) {
            return resolve(data);
        }).catch(function(err) {
            reject(err);
        });
    });
};
var resetPassword = function(username, password, code, state, challengeName) {
    var body = {
        username: username.toLowerCase(),
        password: password,
        state: state,
        challenge: challengeName
    };
    var payload = code ? _object_spread_props(_object_spread({}, body), {
        code: code
    }) : body;
    return new Promise(function(resolve, reject) {
        fetch((0, _Prefix.getURLWithPrefix)("/workspace/challenge"), _object_spread_props(_object_spread({}, apiParams), {
            method: "POST",
            body: JSON.stringify(payload)
        })).then(function(res) {
            return parseResponse(res);
        }).then(function(data) {
            return resolve(data);
        }).catch(function(err) {
            reject(err);
        });
    });
};
var exchangeAuthCodeToAccessToken = function(authCode) {
    return new Promise(function(resolve, reject) {
        Promise.resolve().then(function() {
            return fetch((0, _Prefix.getURLWithPrefix)("/auth/paas/token"), {
                method: "POST",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    code: authCode,
                    client_id: "",
                    grant_type: "e_qr_code"
                })
            });
        }).then(parseResponse).then(function(data) {
            if (data.error) {
                throw data.error;
            }
            resolve(data);
        }).catch(function(err) {
            reject(err);
        });
    });
};
var getUserStatus = function() {
    return new Promise(function(resolve, reject) {
        Promise.resolve().then(function() {
            return fetch((0, _Prefix.getURLWithPrefix)("/workspace/status"), apiParams);
        }).then(parseResponse).then(function(statusData) {
            if (statusData.error) {
                throw statusData.error;
            }
            resolve(statusData);
        }).catch(function(err) {
            reject(err);
        });
    });
};
function forgotPassword(param) {
    var username = param.username, state = param.state;
    return new Promise(function(resolve, reject) {
        fetch((0, _Prefix.getURLWithPrefix)("/workspace/forgotpwd"), _object_spread_props(_object_spread({}, apiParams), {
            method: "POST",
            body: JSON.stringify({
                username: username.toLowerCase(),
                state: state
            })
        })).then(function(res) {
            return parseResponse(res);
        }).then(function(data) {
            return resolve(data);
        }).catch(function(err) {
            reject(err);
        });
    });
}
var passwordPolicyValidation = function(pwd) {
    var policyLowerCase = /(?=.*[a-z])/;
    var policyUpperCase = /(?=.*[A-Z])/;
    var policyLength = /(?=.{8,})/;
    var policyNumbers = /(?=.*[0-9])/;
    if (!policyLength.test(pwd)) {
        return "Use 8 or more characters with a mix of letters and numbers";
    }
    if (pwd.length > 256) {
        return "Password must have length less than 256";
    }
    if (!policyLowerCase.test(pwd)) {
        return "Password must have lowercase characters";
    }
    if (!policyUpperCase.test(pwd)) {
        return "Password must have uppercase characters";
    }
    if (!policyNumbers.test(pwd)) {
        return "Password must have numeric characters";
    }
    return "";
};
function codePolicyValidation(code) {
    var confirmationCodePolicy = /^\S{1,20}$/; // Minimum length of 1. Maximum length of 20, matches anything but a whitespace.
    return !code || !confirmationCodePolicy.test(code) ? "Incorrect code" : "";
}
