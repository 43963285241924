"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
Object.defineProperty(exports, "getInsights", {
    enumerable: true,
    get: function() {
        return getInsights;
    }
});
var _endpoints = require("../utils/endpoints");
var getInsights = function(viewpointID) {
    return {
        type: 'GET_INSIGHTS',
        viewpointID: viewpointID,
        api: {
            method: 'GET',
            endpoint: "".concat(_endpoints.practitionerServicesURL, "/v1/viewpoint/insights?viewpointID=").concat(viewpointID)
        }
    };
};
